import {checkValid} from "../../../globalModels/functions/ModelsValidate";
// import {NovaPoshtaProduct} from "./NovaPoshtaProduct";
import {Packaging} from "../../../globalModels/Packaging";
import {
  NOVAPOSHTA_DELIVERY_SERVICE,
  NOVAPOSHTA_SERVICE_TYPES,
  ORDER_TYPE_EXPRESS,
  ORDER_TYPE_CONSOLIDATION,
  WAREHOUSE_KIEV
} from "../../../../staticData/staticVariables";
let _ = require('lodash');

export function NovaPoshta() {

  this.checkValid = checkValid

  this.data = {
    userId: '',
    user: {},
    id: '',
    type: '',
    sourceOrderId: '',

    phone: '',

    serviceType: NOVAPOSHTA_SERVICE_TYPES.doorsWarehouse,
    serviceTypes: {
      personal: NOVAPOSHTA_SERVICE_TYPES.doorsWarehouse,
      novaPoshta: NOVAPOSHTA_SERVICE_TYPES.warehouseWarehouse
    },

    typeSenderWarehouse: 'warehouse',
    typesSenderWarehouse: {
      warehouse: 'warehouse',
      warehouseNovaPoshta: 'novaPoshta',
    },

    senderAddress: {},
    warehouseAddress: {},

    package: new Packaging(),

    shippingDate: '',

    shippingWarehouse: '',

    placesCount: '',

    declaredValue: '',

    // NovaPoshtaProduct: new NovaPoshtaProduct(),

    description: '',

    typeTransaction: '',
    typeTransactionId: '',

    labelPrice: '',

    trackingNumber: '',

    alreadyCreated: false,
  }

  this.validation = {
    user: false,
    phone: false,
    senderAddress: false,
    warehouseAddress: false,
    description: false,

    shippingDate: false,
    shippingWarehouse: false,
    placesCount: false,
    declaredValue: false,
  }

  this.validationTranslate = {
    user: '',
    phone: '',
    senderAddress: '',
    warehouseAddress: '',
    description: '',

    shippingDate: '',
    shippingWarehouse: '',
    placesCount: '',
    declaredValue: '',
  }

  this.validationTxt = {
    user: false,
    phone: false,
    senderAddress: false,
    warehouseAddress: false,
    description: false,

    shippingDate: false,
    shippingWarehouse: false,
    placesCount: false,
    declaredValue: false,
  }

}


/*****************   SETTERS   ******************/

NovaPoshta.prototype.setType = function (val) {
  this.data.type = val
}

NovaPoshta.prototype.setOrderType = function (id, type) {
  this.setSourceOrderId(id)
  this.setType(type)
}

NovaPoshta.prototype.setNovaPoshtaUser = function (val) {
  this.data.user = val
  this.setUserId(val.id)
}

NovaPoshta.prototype.setUserId = function (val) {
  this.data.userId = val
}

NovaPoshta.prototype.setId = function (val) {
  this.data.id = val
}

NovaPoshta.prototype.setAlreadyCreated = function (val) {
  this.data.alreadyCreated = val
}

NovaPoshta.prototype.setSourceOrderId = function (val) {
  this.data.sourceOrderId = val
}

NovaPoshta.prototype.setPhone = function (val) {
  this.data.phone = val
}

NovaPoshta.prototype.setServiceType = function (val) {
  this.data.serviceType = val
}

NovaPoshta.prototype.setSenderAddress = function (val) {
  this.data.senderAddress = val
}

NovaPoshta.prototype.setNovaPoshtaTypeTransaction = function ({type, id}) {
  this.data.typeTransaction = type
  this.data.typeTransactionId = id
}

NovaPoshta.prototype.setShippingDate = function (val) {
  this.data.shippingDate = val
}

NovaPoshta.prototype.setShippingWarehouse = function (val) {
  this.data.shippingWarehouse = val
}

NovaPoshta.prototype.setPlacesCount = function (val) {
  this.data.placesCount = val
}

NovaPoshta.prototype.setDeclaredValue = function (val) {
  this.data.declaredValue = val
}

NovaPoshta.prototype.setWarehouseAddress = function (val) {
  this.data.warehouseAddress = val
}

NovaPoshta.prototype.setTypeSenderWarehouse = function (val) {
  this.data.typeSenderWarehouse = val
}

NovaPoshta.prototype.setNovaPoshtaItem = function (val) {

  let data = this.data
  let packaging = this.data.package

  if(val.user){
    this.setNovaPoshtaUser(val.user)
  }

  packaging.setPackagingItem({
    weight: parseFloat(val.weight).toFixed(2),
    height: parseFloat(val.height).toFixed(2),
    width: parseFloat(val.width).toFixed(2),
    length: parseFloat(val['length']).toFixed(2),
  })

  // data.type = 1
  // data.sourceOrderId = 1

  if(val.service_type === NOVAPOSHTA_SERVICE_TYPES.doorsWarehouse.value){
    data.senderAddress = {
      sender_address_ref: val.sender_address_ref,
      sender_city_ref: val.sender_city_ref,
      sender_full_address: val.sender_full_address,
    }
  }

  if(val.service_type === NOVAPOSHTA_SERVICE_TYPES.warehouseWarehouse.value){
    data.serviceType = NOVAPOSHTA_SERVICE_TYPES.warehouseWarehouse
    data.warehouseAddress = {
      sender_warehouse_ref: val.sender_warehouse_ref,
      sender_city_ref: val.sender_city_ref,
      sender_full_address: val.sender_full_address,
    }
  }

  data.phone = val.sender_phone ? val.sender_phone.replace(/[^\d]/g, '') : ''

  data.shippingDate = val.shipped_at
  data.shippingWarehouse = val.recipient_warehouse
  data.placesCount = val.places_count
  data.declaredValue = val.declared_value
  data.labelPrice = val.shipping_cost
  data.description = val.description
  data.trackingNumber = val.tracking_number ? val.tracking_number : ''
}



/*****************   GETTERS   ******************/
NovaPoshta.prototype.getType = function () {
  return this.data.type
}

NovaPoshta.prototype.getId = function () {
  return this.data.id
}

NovaPoshta.prototype.getAlreadyCreated = function () {
  return this.data.alreadyCreated
}

NovaPoshta.prototype.getUserId = function () {
  return this.data.userId
}

NovaPoshta.prototype.getSourceOrderId = function () {
  return this.data.sourceOrderId
}

NovaPoshta.prototype.getPhone = function () {
  return this.data.phone
}

NovaPoshta.prototype.getServiceType = function () {
  return this.data.serviceType
}

NovaPoshta.prototype.getSenderAddress = function () {
  return this.data.senderAddress
}

NovaPoshta.prototype.getShippingDate = function () {
  return this.data.shippingDate
}

NovaPoshta.prototype.getShippingWarehouse = function () {
  return this.data.shippingWarehouse
}

NovaPoshta.prototype.getPlacesCount = function () {
  return this.data.placesCount
}

NovaPoshta.prototype.getDeclaredValue = function () {
  return this.data.declaredValue
}

/*******************   FUNCTIONS   *************/

// NovaPoshta.prototype.addNovaPoshtaProduct = function() {
//   this.data.NovaPoshtaProduct.push(new NovaPoshtaProduct)
//
//   return this.data.NovaPoshtaProduct.length
// }
//
// NovaPoshta.prototype.removeNovaPoshtaProduct = function(index) {
//
//   this.data.NovaPoshtaProduct.splice(index, 1)
//
//   return this.data.NovaPoshtaProduct.length
// }

NovaPoshta.prototype.prepareData = function(noLinkedOrder = false) {
  let poshta = this.data
  let packaging = this.data.package.preparePackagingItem(true)

  let data = {
    "delivery_service_id" : NOVAPOSHTA_DELIVERY_SERVICE.id,
    "recipient_warehouse_id" : poshta?.shippingWarehouse?.id ? poshta.shippingWarehouse.id : WAREHOUSE_KIEV.id,

    "service_type": poshta.serviceType.value,

    "sender_phone": poshta.phone.replace(/[^\d]/g, ''),
    "shipped_at": poshta.shippingDate,
    "places_count": poshta.placesCount,
    "declared_value": poshta.declaredValue,
    "description": poshta.description,


    "weight": packaging.weight,
    "length": packaging['length'],
    "width": packaging.width,
    "height": packaging.height,

    "volume_weight": this.data.package.getVolumeWeight4000(),
    "volume_general": this.data.package.getVolumeGeneral(),
  }

  if(poshta.userId !== ''){
    data['user_id'] = poshta.userId
  }

  if(poshta.serviceType === poshta.serviceTypes.personal){
    data['sender_address_ref'] = poshta.senderAddress.sender_address_ref
    data['sender_city_ref'] = poshta.senderAddress.sender_city_ref
    data['sender_full_address'] = poshta.senderAddress.sender_full_address
  }

  if(poshta.serviceType === poshta.serviceTypes.novaPoshta){
    data['sender_warehouse_ref'] = poshta.warehouseAddress.sender_warehouse_ref
    data['sender_city_ref'] = poshta.warehouseAddress.sender_city_ref
    data['sender_full_address'] = poshta.warehouseAddress.sender_full_address

    if(poshta.typeSenderWarehouse === poshta.typesSenderWarehouse.warehouseNovaPoshta){
      data['sender_warehouse_ref'] = poshta.warehouseAddress.warehouse['Ref']
      data['sender_city_ref'] = poshta.warehouseAddress.warehouse['CityRef']
      data['sender_full_address'] = poshta.warehouseAddress.warehouse['CityDescription'] + ' ' +
          poshta.warehouseAddress.warehouse['Description']
    }

  }


  if(poshta.type !== '' && poshta.sourceOrderId !== '' && !noLinkedOrder) {
    switch (poshta.type) {
      case ORDER_TYPE_EXPRESS:
        data['order_express_id'] = parseInt(poshta.sourceOrderId)
        break;
      case ORDER_TYPE_CONSOLIDATION:
        data['consolidation_id'] = parseInt(poshta.sourceOrderId)
        break
    }

  }

  return data
}

NovaPoshta.prototype.prepareGetDeliveryPrice = function() {
  let poshta = this.data
  let packaging = this.data.package.preparePackagingItem()

  let data = {
    "serviceType": poshta.serviceType.value,
    "weight": Math.max(packaging.weight, this.data.package.getVolumeWeight4000()),
    "cost": poshta.declaredValue,
  }

  if (poshta?.placesCount) {
    data["seatsAmount"] = poshta.placesCount
  }

  let recipientWarehouse = _.find(
      poshta.shippingWarehouse.delivery_service_settings,
      {delivery_service_id: NOVAPOSHTA_DELIVERY_SERVICE.id}
  )

  if(recipientWarehouse?.city_ref){
    data['cityRecipient'] = recipientWarehouse.city_ref
  }

  if(poshta.serviceType === poshta.serviceTypes.personal){
    data['citySender'] = poshta.senderAddress.sender_city_ref
  }

  if(poshta.serviceType === poshta.serviceTypes.novaPoshta){
    data['citySender'] = poshta.warehouseAddress.sender_city_ref

    if(poshta.typeSenderWarehouse === poshta.typesSenderWarehouse.warehouseNovaPoshta){
      data['citySender'] = poshta.warehouseAddress.warehouse['CityRef']
    }

  }

  return data

}

/******************  VALIDATIONS  *****************/


NovaPoshta.prototype.userValidation = function () {

  let validationItems = {
    user: this.data.userId
  }
  console.log(validationItems);
  let validationOptions = {
    user: {type: ['empty']},
  }

  return this.checkValid(validationItems, validationOptions)
}

NovaPoshta.prototype.firstValidation = function () {

  let data = this.data

  let validationItems = {
    phone: data.phone,
    description: data.description,

    shippingDate: this.data.shippingDate,
    shippingWarehouse: this.data.shippingWarehouse,
    placesCount: this.data.placesCount,
    declaredValue: this.data.declaredValue,
  }

  let validationOptions = {
    phone: {type: ['phone', 'empty']},
    description: {type: ['empty']},

    shippingDate: {type: ['empty']},
    shippingWarehouse: {type: ['empty']},
    placesCount: {type: ['numeric', 'empty']},
    declaredValue: {type: ['numeric' ,'empty']},
  }

  if(data.serviceType === data.serviceTypes.personal){
    validationItems.senderAddress =  Object.keys(data.senderAddress).length > 0 ? data.senderAddress : ''
    validationOptions.senderAddress = {type: ['empty']}
  } else {
    validationItems.warehouseAddress = Object.keys(data.warehouseAddress).length > 0 ? data.warehouseAddress : ''
    validationOptions.warehouseAddress = {type: ['empty']}
  }


  let packageValidate = this.data.package.packagingValidate()

  return (this.checkValid(validationItems, validationOptions) &&
    packageValidate)
}



/******************  PREPARE DATA  *****************/
