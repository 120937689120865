<template>
  <div class="fragment">
    <ExpressLabelsTableUser
            :navTabs="navTabs"
            :countFilterParams="countFilterParams"
            :filterGetParams="filterGetParams"
            @resetFilter="resetFilter"
            @changeFilter="changeFilter"
            @showMore="showMore"
            @reload="reload"
            @downloadMultipleLabel="downloadMultipleLabel"
            @downloadLabel="downloadLabel"
    />
  </div>
</template>

<script>
  import ExpressLabelsTableUser from "./ExpressLabelsTableUser/ExpressLabelsTableUser.vue";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "ExpressLabelsTable",

    components: {
      ExpressLabelsTableUser,
    },

    mixins: [routeFilter],

    computed: {
      router: function() {
        return this.$route.query
      }
    },

    watch: {
      downloadPermissions() {
        this.checkTabPermission()
      }
    },

    data() {
      return {
        forPage: this.$store.getters.getExpressForPage,
        page: 1,
        onePage: 1,
        filterType: '',
        filterDate: [],
        filterUserName: '',
        filterUserId: '',
        filterId: '',

        countFilterParams: 0,
        filterGetParams: {},


        navTabs: {
        },

      }
    },

    mounted() {

      if(this.$route.query.type !== undefined){
        this.navTabs.all.active = false
        this.navTabs[this.$route.query.type].active = true
      }

      if(this.loadUserAuthorizedData){
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      }

    },

    methods: {

      checkTabPermission() {
        this.filter()
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          type: this.$route.query.type,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,
          id: this.$route.query.id,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        this.$store.commit('setExpressFilter', window.location.search)

        if (next) this.$store.commit('setNextExpressPage', true)
        this.$store.dispatch('fetchExpressLabels', {filter: url}).then(() => {
          this.$store.commit('setNextExpressPage', false)
        })


        this.checkCountFilter(['userName', 'type'])
      },

      generateFilterUrl(page, forPage, exportIds = false) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
          myQuery,
          {
            filterId: 'byOrderExpressIds',
            filterUserId: 'OrderExpressPaymentUserId',
          },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')
          myQuery.whereIn(
            'between_created_at',
            date
          )
        }

        // If generate on export file
        if(exportIds && exportIds.length > 0) {
          myQuery.whereIn('orderExpressIds', exportIds)
        }

        // myQuery.includes('trackingLog')

        return this.generateGetParamsFromQuery(myQuery, forPage, page)

      },

      downloadMultipleLabel() {
        let exportIds

        exportIds = this.getCheckedRows('row-name')

        let date = ''
        if (this.filterDate.length > 0) {
          date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')
          date = date[0] +','+ date[1]
        }

        let routeData = this.$router.resolve({
          path: this.$store.getters.GET_PATHS.generatePdfFile + '/express-labels-print',
          query: {
            id: exportIds.join(','),
            date: date
          }
        })

        window.open(routeData.href, '_blank');
      },

      downloadLabel(id) {
        let routeData = this.$router.resolve({
          path: this.$store.getters.GET_PATHS.generatePdfFile + '/express-labels-print',
          query: {
            id: id,
            date: ''
          }
        })

        window.open(routeData.href, '_blank');
      },

    },

    destroyed() {
      setTimeout(() => {
        this.$store.commit('setExpress', {express: []})
        this.$store.commit('setExpressCommonList', {expressCommonList: []})
      }, 300)
    },

  }
</script>

<style scoped>

</style>
