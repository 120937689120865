<template>
  <div class="fragment">
    <ExpressLabelsTableUserHead
            :showFilter="showFilter"
            :navTabs="navTabs"
            :countFilterParams="countFilterParams"
            @toggleFilter="toggleFilter"
            @filter="$emit('filter')"
            @changeTab="(data) => $emit('changeTab', data)"
            @reload="$emit('reload')"
            @downloadMultipleLabel="$emit('downloadMultipleLabel')"
            @selectAll="selectAll"
    />

    <div class="table-filter-wrap">

      <ExpressLabelsTableUserFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          :navTabs="navTabs"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <ExpressLabelsTableUserTable
              :countFilterParams="countFilterParams"
              :navTabs="navTabs"
              :selectedNow="selectedNow"
              @showMore="$emit('showMore')"
              @resetFilter="$emit('resetFilter')"
              @reload="$emit('reload')"
              @downloadLabel="(id) => $emit('downloadLabel', id)"
      />
    </div>

  </div>
</template>

<script>
  import ExpressLabelsTableUserTable from "./ExpressLabelsTableUserTable/ExpressLabelsTableUserTable.vue";
  import ExpressLabelsTableUserFilter from "./ExpressLabelsTableUserFilter/ExpressLabelsTableUserFilter.vue";
  import ExpressLabelsTableUserHead from "./ExpressLabelsTableUserHead/ExpressLabelsTableUserHead.vue";

  export default {
    name: "ExpressLabelsTableUser",

    components: {
      ExpressLabelsTableUserHead,
      ExpressLabelsTableUserFilter,
      ExpressLabelsTableUserTable,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data() {
      return {
        showFilter: false,
        selectedNow: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      selectAll(val) {
        this.selectedNow = val
      },
    }
  }
</script>

<style scoped>

</style>
