import {NovaPoshta} from "../../components/modules/NovaPoshtaModule/models/NovaPoshta";
import {internalOrdersMixin} from "./internalOrdersMixin";
import {NOVAPOSHTA_LABEL_FILES_TYPES, ORDER_TYPE_CONSOLIDATION} from "../../staticData/staticVariables";

export const novaPoshtaOrdersMixins = {

  mixins: [internalOrdersMixin],

  methods: {

    changeUser(user) {
      this.NP.setPhone(this._.first(user.contacts).phone)
      this.NP.setNovaPoshtaUser(user)
    },

    changePayOrderPopup(val) {
      this.payOrderPopup = val
    },

    createLabel(id, fromTable = false) {
      this.$store.dispatch('createNovaPoshtaLabel', id).then(response => {
        if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

          this.openNotify('success', 'common_notificationRecordCreated')

          if(fromTable) {
            this.$emit('reload')
            if(this.payOrderPopup) this.payOrderPopup = false
            return
          }

          if(this.$store.getters.getIsAdminRights === 'user' && this.NP.data.type === ORDER_TYPE_CONSOLIDATION){
            this.$router.push(this.$store.getters.GET_PATHS.ordersConsolidation)
            return
          }

          if(this.$store.getters.getIsAdminRights === 'user'){
            this.$router.push(this.$store.getters.GET_PATHS.expressOrders)
            return
          }

          this.$router.push(this.$store.getters.GET_PATHS.novaPoshta)

        } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          let errors = response.response.data.errors;

          this.openNotify('error', {txtServer: errors[0]})
        } else {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      })
    },

    getDefaultWarehouse() {
      // get Warehouse for Ship to warehouse field
      const query = new this.Query();
      let url = '?';
      let myQuery = query
        .for('posts')

      myQuery.where('warehouseCode', 'kiev01')

      url = url + myQuery.url().split('?')[1]
      this.$store.dispatch('fetchWarehouses', url).then(() => {
        if(this.$store.getters.getWarehouses.length > 0) {
          this.NP.setShippingWarehouse(this.$store.getters.getWarehouses[0])
        }
      })
    },

    getDataExpress(id) {
      this.$store.dispatch('getExpress', id).then((response) => {
        let express = response.data.data
        console.log(express);
        if(this._.has(express, 'expressOrderInstance')){
          this.NP.data.package.setPackagingItem(express.expressOrderInstance.proform_packaging)

          if(express.expressOrderInstance.warehouse){
            this.NP.setShippingWarehouse(express.expressOrderInstance.warehouse)
          } else {
            this.getDefaultWarehouse()
          }

        }
      })
    },

    getDataConsolidation(id) {
      this.$store.dispatch('getConsolidation', id).then((response) => {
        let conso = response.data.data

        if (conso?.dimensions) {
          this.NP.data.package.setPackagingItem(conso.dimensions)
        }

        if(conso?.warehouse_id){
          this.NP.setShippingWarehouse(conso.warehouse_id)
        } else {
          this.getDefaultWarehouse()
        }

      })
    },

    getDeliveryPrice() {
      if(!this.NP.firstValidation()) return

      let data = this.NP.prepareGetDeliveryPrice()

      this.$store.dispatch('getNovaPoshtaDeliveryPrice', data).then(response => {
        if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

          this.orderPrice = response.data.data.price
          this.changePayOrderPopup(true)
        } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          let errors = response.response.data.errors;

          console.log(errors);
        } else {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      })
    },

    save(draft, edit){
      if(!this.NP.firstValidation()) return

      let data = this.NP.prepareData()
      if (this.NP.getAlreadyCreated()) {
        edit = true
      }
      if(edit){
        data = {
          data: this.NP.prepareData(true),
          id: this.NP.getId()
        }
      }

      if(draft){
        let confirm = () => {
          this.saveOrderInternal(draft, edit, data, 'novaPoshta').then(status => {
            console.log(8888888);
            console.log(status);
            this.successSave(status)
          })

          this.$store.commit('setSaveInDraftsPopup', false)
          return true
        }

        this.saveInDraftsFunc(confirm)
      } else {
        this.saveOrderInternal(draft, edit, data, 'novaPoshta').then(response => {
          this.NP.setId(response.id)
          this.NP.setAlreadyCreated(true)
          this.createLabel(response.id)
          return true
        })
      }



      // switch (orderType) {
      //   case 'novaPoshta':
      //
      //
      //     break
      //   case 'belposhta':
      //     this.$router.push(this.$store.getters.GET_PATHS.belposhta)
      //     break
      // }


    },

    successSave(status) {
      if(!status) return

      if(this.isCurrentUserRoleUser && this.NP.data.type === ORDER_TYPE_CONSOLIDATION){
        this.$router.push(this.$store.getters.GET_PATHS.ordersConsolidation)
        return
      }

      if(this.isCurrentUserRoleUser){
        this.$router.push(this.$store.getters.GET_PATHS.expressOrders)
        return
      }

      this.$router.push(this.$store.getters.GET_PATHS.novaPoshta)
    }

  },

}



export const novaPoshtaOrdersTableMixins = {

  mixins: [novaPoshtaOrdersMixins],

  methods: {
    getDeliveryPriceFromTable(id) {

      let NP = new NovaPoshta()
      this.$store.dispatch('getOrdersInternal', id).then(() => {
        NP.setNovaPoshtaItem(this.$store.getters.getOrdersInternalItem.orderInternal)

        let data = NP.prepareGetDeliveryPrice()

        this.$store.dispatch('getNovaPoshtaDeliveryPrice', data).then(response => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            this.orderPrice = response.data.data.price
            this.changePayOrderPopup(true)
          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            console.log(errors);
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      })
    },

    // openNovaPoshtaLink(link){
    //   let pdfWindow = window.open("label")
    //   pdfWindow.document.write("<style type='text/css'>body {margin: 0; overflow: hidden}</style> <iframe width='100%' height='100%' src='"+ link +"'></iframe>")
    // },

    getNovaPoshtaMarkingUUID(filesArr){
      return this._.find(filesArr, {type: NOVAPOSHTA_LABEL_FILES_TYPES.markingFile})?.uuid
    },
    getNovaPoshtaLabelUUID(filesArr){
      return this._.find(filesArr, {type: NOVAPOSHTA_LABEL_FILES_TYPES.labelFile})?.uuid
    },

  }
}
