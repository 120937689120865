<template>
  <div class="fragment">
    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'express_allOrders',
          'express_allOrdersSub',
          'express_DHLSub',
          'express_fedexSub',
          'express_tntSub',
        ])"></div>

      <div class="content-tabs content-tabs--separator">

      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton
            :count="countFilterParams"
            :showFilter="showFilter"
            @toggleFilter="$emit('toggleFilter')"
        />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">
        <DefaultCheckbox
            :label="$t('warehouseManagement_selectAll.localization_value.value')"
            @input="(val) => $emit('selectAll', val)"
        />
      </div>
      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'common_multipleDownload',
        ])"></div>
        <MainButton class="btn-fit-content ml-2"
                    :value="$t('common_multipleDownload.localization_value.value')"
                    @click.native="$emit('downloadMultipleLabel')"
        >
        </MainButton>
      </div>
    </div>

  </div>
</template>

<script>
import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import {
  DHL_DELIVERY_SERVICE,
  FEDEX_DELIVERY_SERVICE,
  TNT_DELIVERY_SERVICE
} from "../../../../../../../staticData/staticVariables";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";

export default {
  name: "ExpressLabelsTableUserHead",
  components: {
    MainButton,
    ToggleFilterButton,
    DefaultCheckbox,
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
    navTabs: Object,
  },

  computed: {

  },

  data() {
    return {
      isModalChooseExpressOrderPopup: false,
      isModalAddressEditingPopup: false,
      DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
      FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
      TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,
    }
  },

  methods: {
  }
}
</script>

<style scoped>

</style>
