<template>
  <div class="fragment">
    <template v-if="$store.getters.getExpress.length > 0 && !$store.getters.getExpressLoading">
      <div class="site-table-wrap products-table express-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'express_order',
          'express_Created',
          'common_manage',
          'common_delete',
        ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th> {{ $t('express_order.localization_value.value') }}</th>
            <th style="padding: 0 15px;"> {{ $t('express_Created.localization_value.value') }}</th>
            <th v-if="isAdmin"> {{ $t('common_user.localization_value.value')}}</th>
            <th style="width: 100%;"></th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr
              v-for="(item, index) in $store.getters.getExpress"
              :key="index">
            <td>
              <DefaultCheckbox
                      class="empty-label"
                      :selectedNow="selectedNow"
                      :dataValue="item.order.id"
              />
            </td>
            <td>
              <div class="d-flex align-items-center">
                <span class="table-ico mr-2">
                  <img
                          v-if="TNT_DELIVERY_SERVICE.id == item.order.delivery_service_id"
                          src="/img/company-icons-group/tab-tnt.svg" alt="ico">
                  <img
                          v-if="DHL_DELIVERY_SERVICE.id == item.order.delivery_service_id"
                          src="/img/company-icons-group/tab-dhl.svg" alt="ico">
                  <img
                          v-if="FEDEX_DELIVERY_SERVICE.id == item.order.delivery_service_id"
                          src="/img/company-icons-group/tab-fedex.svg" alt="ico">
                </span>
                <router-link
                        :to="$store.getters.GET_PATHS.expressOrderShow + '/' +
												getExpressTypeById(item.order.delivery_service_id).name + '/' + item.order.id"
                        class="table-link btn-style">
                  #{{item.order.id}}
                </router-link>
<!--                <span class="table-ico ml-2 w16">-->
<!--                  <LabelCircle v-if="item.payment_transaction_id"-->
<!--                               :color="'green'" :value="'T'" />-->
<!--                  <LabelCircle v-else-->
<!--                               :color="'gray-light'" :value="'N'" />-->
<!--                </span>-->
              </div>
            </td>

            <td style="white-space: nowrap; padding: 0 15px;">{{ item.order.created_at | moment("DD MMM, YYYY") }}</td>

            <td v-if="isAdmin">
              <TableUserColumn
                      v-if="isAdmin"
                      :item="item"
              />
            </td>
            <td></td>
            <td>
              <LinkButton
                  :value="$t('common_download.localization_value.value')"
                  :type="'edit'"
                  @click.native="$emit('downloadLabel', item.order.id)"
              />
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getExpress"
                 :key="index">
              <ExpressLabelsTableUserTableMobile
                      :item="item"
                      @downloadLabel="$emit('thermalPrint', item.order.id)"
                      :selectedNow="selectedNow"
                      :dataValue="item.id"
              />
            </div>

          </div>
        </div>
      </div>


      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
                  v-if="$store.getters.getExpressCommonList.next_page_url !== null && $store.getters.getExpress.length > 0"
                  @click.native="$emit('showMore')"
                  :count="$store.getters.getExpressCommonList.total - $store.getters.getExpressForPage * $store.getters.getExpressCommonList.current_page < $store.getters.getExpressForPage ?
                  $store.getters.getExpressCommonList.total - $store.getters.getExpressForPage * $store.getters.getExpressCommonList.current_page:
                  $store.getters.getExpressForPage"
          />
<!--          <ExportBtn-->
<!--              :customType="_.has($store.getters.getExpressCommonList, 'addition_export') ? $store.getters.getExpressCommonList.addition_export : null"-->
<!--              @downloadFiles="type => $emit('downloadFiles', type)"-->
<!--                  class="table-bottom-btn__right"/>-->
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getExpressLoading === false && $store.getters.getExpress.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>


  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  // import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import {
    DHL_DELIVERY_SERVICE,
    EXPRESS_DELIVERY_STATUSES,
    FEDEX_DELIVERY_SERVICE, FEDEX_PACKAGING_TYPES, ORDER_TYPE_EXPRESS,
    TNT_DELIVERY_SERVICE
  } from "../../../../../../../staticData/staticVariables";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ExpressLabelsTableUserTableMobile from "./ExpressLabelsTableUserTableMobile/ExpressLabelsTableUserTableMobile";
  import {expressMixins} from "../../../../../../../mixins/expressMixins/expressMixin";
  import {paymentBlockFunc} from "../../../../../../../mixins/dashboardMixins/paymentBlockFunc";
  import {novaPoshtaOrdersTableMixins} from "../../../../../../../mixins/internalOrdersMixins/novaPoshtaOrdersMixin";
  import {
    cdekGetLabelMixin,
    cdekOrdersTableMixins
  } from "../../../../../../../mixins/internalOrdersMixins/сdekOrdersMixin";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";


  export default {
    name: "ExpressLabelsTableUserTable",

    components: {
      LinkButton,
      TableUserColumn,
      ExpressLabelsTableUserTableMobile,
      NoResult,
      ShowMore,
      // ExportBtn,
      DefaultCheckbox,
    },

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    mixins: [
      mixinDetictingMobile,
      expressMixins,
      paymentBlockFunc,
      novaPoshtaOrdersTableMixins,
      cdekGetLabelMixin,
      cdekOrdersTableMixins
    ],

    watch: {
      loadUserAuthorizedData() {
        this.checkUserCourierAvailableTime()
      },
    },

    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,
        fixShowManageBar: true,
        showContent: false,
        hideEditField: false,

        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,

        EXPRESS_DELIVERY_STATUSES: EXPRESS_DELIVERY_STATUSES,

        ORDER_TYPE_EXPRESS: ORDER_TYPE_EXPRESS,

        isModalCallingCourierPopup: false,
        isModalCancelCourierPopup: false,
        isModalBuyLabelPopup: false,
        isModalReferencePopup: false,
        isModalBuyLabelPopupConfirm: false,
        isModalCourierDetailPopup: false,

        orderPopupData: {},
        courierDetailData: {},

        idCostExpressLabel: -1,
        deliveryServiceId: -1,

        authorizePayData: {},
        authorizePaySum: -1,
        authorizeInvoiceId: -1,
        authorizePaymentPopup: false,
        braintreePaymentPopup: false,
        thankYouPopupPopup: false,
        serverError: false,
        authorizeError: false,

        payOrderPopup: false,

        referenceType: '',
        referenceNumber: '',

        availableCourierTime: null,

        FEDEX_PACKAGING_TYPES: FEDEX_PACKAGING_TYPES,
      }
    },

    mounted() {
      if(this.loadUserAuthorizedData){
        this.checkUserCourierAvailableTime()
      }
    },

    methods: {

      changeAlarm(item, status) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_yes',
          no: 'common_confirmNo'
        }

        let confirm = () => {
          let data = {
            'alarm_status': status === 1 ? 0 : 1,
          }

          this.$store.dispatch('changeExpressAlarm', {id: item.id, data: data}).then(response => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

                let itemExpress = this._.find(this.$store.getters.getExpress, {id: item.id})
                itemExpress['alarm_status'] = data['alarm_status']
                this.openNotify('success', 'common_notificationRecordChanged')
                // this.$emit('reload')
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },


      checkUserCourierAvailableTime(){
        this.$store.dispatch('identifyPickupCity',
          {user_id: this.loadUserAuthorizedData.user.id}
        ).then(response => {
          this.availableCourierTime = this.getRespData(response).courier_city
        }).catch(error => this.createErrorLog(error))

      },

      createLabelNovaPoshta(item) {
        this.orderId = item.order_internal_id
        this.getDeliveryPriceFromTable(item.order_internal_id)
      },

      getDeliveryPriceCDEK(item) {
        this.orderId = item.order_internal_id
        this.getDeliveryPriceCDEKFromTable(item.order_internal_id)
      },

      // barcodeCDEK(item) {
      //   this.getCDEKProductLabel(item.order_internal_id)
      // },
      //
      // waybillCDEK(item) {
      //   this.getCDEKOrderLabel(item.order_internal_id)
      // },



      submitAuthorizeInvoicePay(data){
        this.payAuthorizeInvoice({data: data, invoiceId: this.authorizeInvoiceId}).then(status => {
          if(status){
            this.$store.dispatch('afterInvoicePayStatusUpdate', this.idCostExpressLabel).then((response => {
              if(response.data.data.status){
                let orderItem = this._.find(this.$store.getters.getExpress, {id: parseInt(this.idCostExpressLabel)})
                orderItem.status = this.EXPRESS_DELIVERY_STATUSES.WAIT_FOR_PAY_STATUS
              }
            }))
          }
          this.authorizePaymentPopup = false

          setTimeout(() => {
            this.changeThankYouPopupPopup(true)
          }, 200)
        })
      },


      afterPay(){
        this.$store.dispatch('afterInvoicePayStatusUpdate', this.idCostExpressLabel).then((response => {
          if(response.data.data.status){
            let orderItem = this._.find(this.$store.getters.getExpress, {id: parseInt(this.idCostExpressLabel)})
            orderItem.status = this.EXPRESS_DELIVERY_STATUSES.WAIT_FOR_PAY_STATUS
          }

          this.authorizePaymentPopup = false

          setTimeout(() => {
            this.changeThankYouPopupPopup(true)
          }, 200)
        }))
      },


      exportDocsExpress(id, deliveryServiceId) {
        let orderExpressType = this.getExpressTypeById(deliveryServiceId)
        this.$store.dispatch('getExportCommercialInvoice', {order_express_id: id}).then(response => {
          this.globalDownloadBase64File('application/docx', response.data.data.commercial_invoice_file, `${orderExpressType.label} docs.docx`)
        }).catch(error => this.createErrorLog(error))
      },

      // exportCommercialInvoice(id) {
      //   this.$store.dispatch('getExpressProform', {order_express_id: id}).then((response) => {
      //     this.globalOpenPdfBase64(response.data.data.document_package)
      //   })
      // },

      exportConfirmationDocument(id, type) {
        this.$store.dispatch('getExpressConfirmationDocument', {order_express_id: id, fileType: type}).then(response => {
          this.globalDownloadBase64File('application/docx', response.data.data.file, `${type[0].toUpperCase()}${type.slice(1)}.docx`)
        }).catch(error => this.createErrorLog(error))
      },

      payForAuthorize({labelNeedMoneyAmount, invoiceId, userBalance, orderAmount, paySystemCommission}) {
        this.authorizePayData = {
          userBalance,
          orderAmount,
          paySystemCommission,
        }
        this.authorizePaySum = labelNeedMoneyAmount
        this.authorizeInvoiceId = invoiceId
        this.closeBuyLabelPopup()

        if(this._.has(this.currentPermissions, this.PERMISSIONS.CUSTOMER_PAYMENT_BRAINTREE)) {
          this.changeBraintreePaymentPopup(true)
        } else {
          this.changeAuthorizePaymentPopup(true)
        }
      },

      showCourierDetailPopup(item) {
        let type = this._.find(this.navTabs, {id: item.delivery_service_id})

        if (type?.name === DHL_DELIVERY_SERVICE.name) {
          this.courierDetailData = {
            confirmationNumber: item?.pickup_data?.ConfirmationNumber,
            courierArrivalDate: item?.courier_arrival_date,
            contactPersonName: Array.isArray(item?.warehouse) !== true ? item?.warehouse?.sender_name : item?.courier_contact_name,
            contactPhone: Array.isArray(item?.warehouse) !== true ? item?.warehouse?.sender_phone : item?.courier_contact_phone,
            packageLocation: Array.isArray(item?.warehouse) !== true ? item?.warehouse?.sender_address : item?.sender_contact_address,
          }
        }
        else if (type?.name === FEDEX_DELIVERY_SERVICE.name) {
          this.courierDetailData = {
            confirmationNumber: item?.pickup_data?.PickupConfirmationNumber,
            courierArrivalDate: item?.courier_arrival_date,
            contactPersonName: item?.courier_alternative_address ? item?.courier_alternative_address?.contact_name : Array.isArray(item?.warehouse) !== true ? item?.warehouse?.sender_name : item?.sender_contact_name,
            contactPhone: item?.courier_alternative_address ? item?.courier_alternative_address?.phone : Array.isArray(item?.warehouse) !== true ? item?.warehouse?.sender_phone : item?.sender_phone_number,
            packageLocation: item?.courier_alternative_address ? item?.courier_alternative_address?.address : Array.isArray(item?.warehouse) !== true ? item?.warehouse?.sender_address : item?.sender_contact_address,
          }
        }

        if(item.courier_from_to_time !== null) {
          let courierTime = item.courier_from_to_time.split('|')
          this.courierDetailData["timeFrom"] = this.$moment(courierTime[0], 'HH:mm').format('HH:mm')
          this.courierDetailData["timeTo"] = this.$moment(courierTime[1], 'HH:mm').format('HH:mm')
        }

        this.isModalCourierDetailPopup = true

      },

      showCallingCourierPopup(item) {
        let type = this._.find(this.navTabs, {id: item.delivery_service_id})

        let senderData = item.user?.use_alternative_address === 1 ? item.user.contacts[1] : item.user.contacts[0]
        let senderWarehouseData = item.warehouse?.id ? item.warehouse : null

        this.orderPopupData = {
          id: item.id,
          name: senderWarehouseData ? senderWarehouseData?.sender_name : senderData?.user_full_name,
          phone: senderWarehouseData ? senderWarehouseData?.sender_phone : senderData?.phone,
          deliveryDate: item?.delivery_date,
          deliveryTypeName: type?.name,
          deliveryTypeId: type?.id,

          country: {
            id: senderWarehouseData ? senderWarehouseData?.country.id : senderData?.country?.id,
            label: senderWarehouseData ? senderWarehouseData?.country?.name : senderData?.country?.name,
            code_iso_2: senderWarehouseData ? senderWarehouseData?.country?.code_iso_2 : senderData?.country?.code_iso_2,
          },
          city: senderWarehouseData ? senderWarehouseData?.sender_city : senderData?.city,
          address: senderWarehouseData ? senderWarehouseData?.sender_address : senderData?.address,
          region: senderWarehouseData ? senderWarehouseData?.sender_contact?.region?.name : senderData?.region?.name,
          zip: senderWarehouseData ? senderWarehouseData?.sender_postcode : senderData?.zip,
        }

        this.isModalCallingCourierPopup = true
      },

      closeCallingCourierPopup() {
        this.orderPopupId = -1
        this.deliveryNamePopup = ''
        this.isModalCallingCourierPopup = false
      },

      closeCourierDetailPopup() {
        this.isModalCourierDetailPopup = false
      },

      expressCancelCourierPopup(item) {
        this.orderId = item.id
        let type = this._.find(this.navTabs, {id: item.delivery_service_id})
        this.orderPopupData = {
          deliveryTypeName: type.name,
        }
        this.isModalCancelCourierPopup = true
      },

      closeCancelCourierPopup() {
        this.orderId = -1
        this.isModalCancelCourierPopup = false
      },

      confirmCallCourier() {
        this.$emit('reload')
        this.isModalCallingCourierPopup = false
      },

      changeTrackingNumberExpress({value, id}) {
        this.$store.dispatch('addTrackNumExpress', {
          id: id,
          data: {tracking_number: value}
        }).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationStatusChanged')
            let express = this.$store.getters.getExpress
            this._.find(express, {id: id}).tracking_number = value
          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            setTimeout(() => {
              this.openNotify('error', {txtServer: errors['tracking_number'][0]})
            }, 200)

          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }).catch(error => this.createErrorLog(error))
      },

      changeNovaPoshtaTrackingNumberExpress({value, id}) {
        this.$store.dispatch('addNewPoshtaTrackNumExpress', {
          id: id,
          data: {tracking_number: value}
        }).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationStatusChanged')
            let express = this.$store.getters.getExpress
            this._.find(express, {id: id}).new_post_tracking_number = value
          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            setTimeout(() => {
              this.openNotify('error', {txtServer: errors['tracking_number'][0]})
            }, 200)

          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }).catch(error => this.createErrorLog(error))
      },

      expressCancelCourier(deliveryId, orderId){
        let type = this._.find(this.navTabs, {id: deliveryId})

        this.$store.dispatch('cancelCourier', {
          type: type.name,
          data: {
            'order_express_id': orderId,
          }
        }).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationStatusChanged')

            this.$emit('reload')

          } else if(response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            // let errTxt = errors
            this.openNotify('error', {txtServer: errors})
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      },

      showBuyLabelPopup(id, deliveryServiceId, confirm = false) {
        this.idCostExpressLabel = id
        this.isModalBuyLabelPopup = true
        this.isModalBuyLabelPopupConfirm = confirm
        this.deliveryServiceId = deliveryServiceId

      },



      closeBuyLabelPopup() {
        this.isModalBuyLabelPopup = false
      },

      showReferencePopup(item, type) {
        this.referenceType = type

        if(type === 'refBooking')
          this.referenceNumber = item.booking_reference_number
        else
          this.referenceNumber = item.reference_number

        this.isModalReferencePopup = true
      },

      closeReferencePopup() {
        this.referenceType = ''
        this.referenceNumber = ''
        this.isModalReferencePopup = false
      },


    },

  }
</script>

<style scoped lang="scss">

  .site-table thead th,
  .site-table tbody td{
    padding: 20px 4px;
    font-size: 13px;
  }

  .site-table td:nth-child(2),
  .site-table td:nth-child(3),
  .site-table th:nth-child(2),
  .site-table th:nth-child(3) {
    padding-left: 0;
  }

  .site-table td:nth-child(9) .table-ico {
    width: 20px;
  }

  /deep/ .site-table .manager-btn__list>.link-btn {
    margin-right: 10px;
    padding-right: 10px;
  }

  .docs-activity-block{
    display: flex;
    justify-content: flex-start !important;
    margin-top: 3px;
  }

  .site-table .ellipsis {
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }

  /deep/ .cursor-pointer-checkbox .default-checkbox__label{
    cursor: pointer;
  }

  /deep/ .site-table .brick-btn {
    font-size: 12px;
  }

  .alarm-style {
    padding: 2px 4px;
    border: 1px solid #eb5757;
    border-radius: 4px;
    text-transform: uppercase;
    background-color: #eb5757;
    color: white;
    cursor: pointer;
    font-size: 12px;
  }

  @media (max-width: 1830px) {
    /deep/ .express-table .table-manage-list .manager-btn {
      width: 84px;
    }
  }

  @media (min-width: 1830px) {
    /deep/ .express-table .table-manage-list .manager-btn {
      width: 190px;
    }

    .express-table .site-table tbody td:last-child{
      padding-right: 5px;
    }
  }
</style>
