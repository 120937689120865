<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['proform_Naming'])"></span>
        <DefaultInput
            :label="$t('express_order.localization_value.value')"
            :type="'text'"
            v-model="id"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['requestReturn_Date'])"></span>
        <DatePickerDefault
                :label="$t('requestReturn_Date.localization_value.value')"
                v-model="date"
        >
          <template slot="body">
            <date-picker
                    v-model="date"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

<!--      <div class="table-filter__item"-->
<!--           v-if="isAdmin"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>-->
<!--        <SearchSelect-->
<!--                :options="options"-->
<!--                :label="$t('shop_user.localization_value.value')"-->
<!--                :optionsLabel="'email.contacts[0]'"-->
<!--                :functionSearch="functionSearchUserFilter"-->
<!--                @change="changeUsersFilter"-->
<!--                :selected="userName"-->
<!--                :typeSelect="'users'"-->
<!--                :userEmail="true"-->
<!--        />-->
<!--      </div>-->
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import DatePicker from 'vue2-datepicker'
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";

  export default {
    name: "ExpressLabelsTableUserFilter",
    components: {
      DatePicker,
      DatePickerDefault,
      FilterBlock,
      MainButton,
      DefaultInput,
    },

    mixins: [filterFunc, usersFunc],

    props: {
      navTabs: Object,
      showFilter: Boolean,
      filterGetParams: Object,
      selectHandmade: Array,
    },

    mounted() {

    },

    data(){
      return {

        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
        deliveryDate: this.filterGetParams.deliveryDate ? this.generateFilterDate(this.filterGetParams.deliveryDate, 'MM/DD/YY'): '',
        id: this.filterGetParams.id ? this.filterGetParams.id : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

        options: [],

        filterCounts: [
          'date',
          'id',
          'userName',
          'userId',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.deliveryDate = newVal.deliveryDate ? this.generateFilterDate(newVal.deliveryDate, 'MM/DD/YY') : ''
        this.id = newVal.id ? newVal.id : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },


      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')
        data.deliveryDate = this.changeDateParams(this, 'deliveryDate')

        this.$emit('changeFilter', data)
      },

    },
  }
</script>

<style scoped>

</style>
