<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <DefaultCheckbox class="mt-1"
                         :selectedNow="selectedNow"
                         :dataValue="dataValue"
        />
        <router-link
            :to="$store.getters.GET_PATHS.expressOrderShow + '/' +
												getExpressTypeById(item.order.delivery_service_id).name + '/' + item.order.id"
            class="table-link btn-style">
          <div class="table-card__item-number">
            #{{item.order.id}}
          </div>
        </router-link>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                      :item="item"
                      :mobile="true"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('express_receiver.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.recipient_contact_name}}
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('express_order.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <span class="table-ico pb-1">
                 <img
                     v-if="TNT_DELIVERY_SERVICE.id == item.order.delivery_service_id"
                     src="/img/company-icons-group/tab-tnt.svg" alt="ico">
                  <img
                      v-if="DHL_DELIVERY_SERVICE.id == item.order.delivery_service_id"
                      src="/img/company-icons-group/tab-dhl.svg" alt="ico">
                  <img
                      v-if="FEDEX_DELIVERY_SERVICE.id == item.order.delivery_service_id"
                      src="/img/company-icons-group/tab-fedex.svg" alt="ico">
              </span>
              #{{item.id}}
              <span class="table-ico ml-2">
                  <LabelCircle v-if="item.payment_transaction_id"
                               :color="'green'" :value="'T'" />
                  <LabelCircle v-else
                               :color="'gray-light'" :value="'N'" />
                </span>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('express_Created.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <LinkButton
              :value="$t('common_download.localization_value.value')"
              :type="'edit'"
              @click.native="$emit('downloadLabel')"
          />
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  // import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import {
    DHL_DELIVERY_SERVICE,
    FEDEX_DELIVERY_SERVICE,
    TNT_DELIVERY_SERVICE
  } from "../../../../../../../../staticData/staticVariables";
  import {expressMixins} from "../../../../../../../../mixins/expressMixins/expressMixin";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import LabelCircle from "../../../../../../../coreComponents/LabelCircle/LabelCircle";

  export default {
    name: "ExpressLabelsTableUserTableMobile",

    components: {
      LabelCircle,
      TableUserColumn,
      LinkButton,
      DefaultCheckbox,
    },

    mixins: [expressMixins],

    props: {
      item: Object,
      selectedNow: Boolean,
      dataValue: Number,
    },

    data() {
      return{
        show: false,
        hideEditField: false,

        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,
      }
    },

    watch: {
      item(newVal) {
        this.item = newVal
      },
    },

    methods: {
    }

  }
</script>

<style scoped>
.table-card__item-content .ellipsis {
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
</style>
